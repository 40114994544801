import axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate, useParams } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';
import Swal from 'sweetalert2'
import ReactPlayer from 'react-player'
import { Alert } from "bootstrap";
import $ from 'jquery';

export const UserQuestion = () => {
    const params = useParams();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [photo, setPhoto] = useState([]);
    const [video, setVideo] = useState([]);
    const [sound, setSound] = useState([]);

    const { t, i18n } = useTranslation()
    const [dataa, setDataa] = useState([]);
    const [questionType, setquestionType] = useState([]);
    const [questionCount, setquestionCount] = useState([]);
    const [questionNumber, setquestionNumber] = useState([]);
    const [truefalseanswercount, settruefalseanswercount] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [sentenceanswercount, setsentenceanswercount] = useState([]);
    const [leftanswersrealmatch, setleftanswersrealmatch] = useState([]);
    const [rightanswersrealmatch, setrightanswersrealmatch] = useState([]);
    const [answercountcheck, setanswercountcheck] = useState([]);

    const navigate = useNavigate();

    const myStyle = {
        position: "relative"
    };

    const myStyle3 = {
        width: "50%",
        margin: "auto",
    };
    const answerHandle = async (e) => {
        alert(document.getElementById("1").value)
        alert(document.getElementById("2").value)
        alert(document.getElementById("3").value)
        alert(document.getElementById("4").value)

        // e.preventDefault();
        // try {
        //     const headerss = {
        //         "accept": "*/*",
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Credentials': 'false',
        //         'Authorization': 'Bearer ' + user.token
        //     }
        //     let contentType = document.getElementById('contentType').value;
        //     let subjectid = document.getElementById('subjectId').value;
        //     let tagid = document.getElementById('tagId').value;

        //     let sortId = document.getElementById('sortId').value;
        //     let contentStr = cnt1;
        //     let detail = cnt2;
        //     let notfyText = cnt3;
        //     let answer = document.getElementById('answer').value;

        //     //let detail = document.getElementById('detail').value;
        //     //let notfyText = document.getElementById('notfyText').value;
        //     //let answer = document.getElementById('answer').value;

        //     let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
        //     await axios({
        //         method: 'Post',
        //         url: url,
        //         data: {
        //             contentType: contentType,
        //             subjectid: subjectid,
        //             tagid: tagid,
        //             sortId: sortId,
        //             contentStr: contentStr,
        //             detail: detail,
        //             notfyText: notfyText,
        //             answer: answer,
        //             isActive: true
        //         },
        //         headers: headerss
        //     }).then(res => {
        //         console.log(res);
        //         if (res.status === 200) {
        //             if (res.data['success']) {
        //                 Swal.fire({
        //                     title: "Başırılı!",
        //                     icon: "success",
        //                     confirmButtonText: `Tamam`
        //                 });
        //             }
        //             else {
        //                 setMsg(res.data['message']);
        //             }
        //         }
        //         else {
        //             setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
        //         }
        //     });
        // } catch (err) {
        //     alert(err.message)
        //     setMsg(err.message);
        // }
        // window.location.reload();
    }
    function nextquestion() {
        var n = Number(params['id']);
        var newId = (n + 1).toString();
        if (Number(newId) > questionCount) {
            navigate("/dashboard/user/course/subject");
        }
        else {
            window.location.href = "/dashboard/user/course/question/" + newId;
        }
    }
    const answerChoiceHandle = async (e) => {

        var rates = document.getElementsByName('answerRate');
        var rate_value;
        for (var i = 0; i < rates.length; i++) {
            if (rates[i].checked) {
                rate_value = rates[i].value;
            }
        }

        const fetchdata = async () => {

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        questionId: questionNumber,
                        answer: rate_value.toString()
                    },
                    headers: headerss
                }).then(res => {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Hatalı Cevap",
                            text: dataa.notfyText,
                            icon: "warning",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                });
            }
            catch (err) {
                alert(err.message);
                setMsg(err);
            }
        }

        e.preventDefault();
        fetchdata();
    }
    const answerMatchHandle = async (e) => {
        let answers = JSON.parse(dataa.detail);
        let readedAnswer = ""
        for (let i = 0; i < answers.length; i++) {
            if (i === answers.length - 1) {
                readedAnswer += document.getElementById(i).innerText
            }
            else {
                readedAnswer += document.getElementById(i).innerText + " "
            }
        }
        const fetchdata = async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        questionId: questionNumber,
                        answer: readedAnswer.toString()
                    },
                    headers: headerss
                }).then(res => {
                    console.log(res.data)
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Hatalı Cevap",
                            text: dataa.notfyText,
                            icon: "warning",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                });
            }
            catch (err) {
                alert(err.message);
                setMsg(err);
            }
        }

        e.preventDefault();
        fetchdata();
    }

    const answerTrueFalseHandle = async (e) => {
        var answer = ""
        for (let i = 0; i < truefalseanswercount; i++) {
            if (i === truefalseanswercount - 1) {
                answer += document.getElementById(i).value;
            }
            else {
                answer += document.getElementById(i).value + ",";
            }
        }
        const fetchdata = async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        questionId: questionNumber,
                        answer: answer
                    },
                    headers: headerss
                }).then(res => {
                    console.log(res.data)
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Hatalı Cevap",
                            text: dataa.notfyText,
                            icon: "warning",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                });
            }
            catch (err) {
                alert(err.message);
                setMsg(err);
            }
        }

        e.preventDefault();
        fetchdata();
    }
    const answerRealMatchHandle = async (e) => {
        let a = 0
        for (let i = 0; i < leftanswersrealmatch.length; i++) {
            if (leftanswersrealmatch[i] === rightanswersrealmatch[i]) {
                a = a + 1
            }
        }
        if (leftanswersrealmatch.length = a) {
            alert("hepsi doğru")
            const fetchdata = async () => {
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
                    await axios({
                        method: 'GET',
                        url: url,
                        params: {
                            questionId: questionNumber,
                            answer: 1
                        },
                        headers: headerss
                    }).then(res => {
                        console.log(res.data)
                        if (res.data['success']) {
                            Swal.fire({
                                title: "Başırılı!",
                                icon: "success",
                                confirmButtonText: `Tamam`,
                                timer: 3000,
                            }).then(() => {
                                let newurl = Number(params['id']) + 1;
                                window.location.href = "/dashboard/user/course/question/" + newurl;
                            });
                        }
                        else {
                            Swal.fire({
                                title: "Hatalı Cevap",
                                text: dataa.notfyText,
                                icon: "warning",
                                confirmButtonText: `Tamam`,
                            }).then(() => {
                                let newurl = Number(params['id']) + 1;
                                window.location.href = "/dashboard/user/course/question/" + newurl;
                            });
                        }
                    });
                }
                catch (err) {
                    alert(err.message);
                    setMsg(err);
                }
            }
            fetchdata();
        }
        e.preventDefault();

    }
    const answerSentenceHandle = async (e) => {
        let answ = JSON.parse(dataa.detail);
        let realanswer = ""
        for (let i = 0; i < answ.length; i++) {
            if (i + 1 === answ.length) {
                realanswer += document.getElementById(i).selectedIndex;
            }
            else {
                realanswer += document.getElementById(i).selectedIndex + ",";
            }
        }
        const fetchdata = async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        questionId: questionNumber,
                        answer: realanswer.toString()
                    },
                    headers: headerss
                }).then(res => {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Hatalı Cevap",
                            text: dataa.notfyText,
                            icon: "warning",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                });
            }
            catch (err) {
                alert(err.message);
                setMsg(err);
            }
        }

        e.preventDefault();
        fetchdata();
    }

    const answerWriteHandle = async (e) => {
        let answer = document.getElementById("writeAnswer").value;
        const fetchdata = async () => {

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        questionId: questionNumber,
                        answer: answer.toString()
                    },
                    headers: headerss
                }).then(res => {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Hatalı Cevap",
                            text: dataa.notfyText,
                            icon: "warning",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + newurl;
                        });
                    }
                });
            }
            catch (err) {
                alert(err.message);
                setMsg(err);
            }
        }

        e.preventDefault();
        fetchdata();
    }

    const handleClick = event => {
        let answers = JSON.parse(dataa.detail);
        var txt = document.getElementById(event.currentTarget.id);
        let buttonText = document.getElementById(txt.id).innerText;
        for (let i = 0; i < answers.length; i++) {
            let writeArea = document.getElementById(i).innerHTML;
            if (writeArea.length === 72) {
                document.getElementById(i).innerText = buttonText
                document.getElementById(txt.id).innerText = "."
                break;
            }
        }
    };

    const handleClickrealmatch = event => {
        var x = event.currentTarget.id;
        var y = x.split('.')[0]
        var button = document.getElementById(event.currentTarget.id);
        var getOpacity = function (el) {
            return el.style.opacity;
        }
        var currentOpacity = parseFloat(getOpacity(button));
        var newOpacity = (currentOpacity < 0.9) ? currentOpacity + 0.4 : newOpacity = 0.5;
        button.style.opacity = newOpacity;
        if (leftanswersrealmatch.length !== 0) {
            setleftanswersrealmatch(oldArray => [...oldArray, y]);
        }
        else {
            setleftanswersrealmatch([y])
        }
    };


    const handleClickrealmatchfile = event => {
        var x = event.currentTarget.id;
        var y = x.split('.')[0]
        var button = document.getElementById(event.currentTarget.id);
        var getOpacity = function (el) {
            return el.style.opacity;
        }
        var currentOpacity = parseFloat(getOpacity(button));
        var newOpacity = (currentOpacity < 0.9) ? currentOpacity + 0.4 : newOpacity = 0.5;
        button.style.opacity = newOpacity;
        if (rightanswersrealmatch.length !== 0) {
            setrightanswersrealmatch(oldArray => [...oldArray, y]);
        }
        else {
            setrightanswersrealmatch([y])
        }

        //event.preventDefault();
    };

    useEffect(() => {
        console.log("use effect")
        setPhoto(false);
        setVideo(false);
        setSound(false);
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetContents";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        sortId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setquestionNumber(res.data['data']['contents'][params['id']]['id'])
                            // if (Number(res.data['data']['contents'].length) >= Number(params['id'])) {
                            //     navigate("/dashboard/user/course/subject");
                            //     window.location.href = "/dashboard/user/course/subject";
                            // }
                            let realData = res.data['data']['contents'][params['id']]
                            setDataa(realData);
                            setquestionCount(res.data['data']['contents'].length)
                            if (res.data['data']['contents'].length !== 0) {
                                for (let i = 0; i < res.data['data']['contents'].length; i++) {
                                    let num = Number(i)
                                    let activity = false;
                                    if (Number(params['id']) === Number(i)) {
                                        activity = true;
                                    }
                                    pagination.push(
                                        {
                                            "id": i + 1,
                                            "href": "/dashboard/user/course/question/" + num.toString(),
                                            "isActive": activity
                                        }
                                    )
                                }
                            }
                            else {
                                alert("zort");
                            }
                            setquestionType(res.data['data']['contents'][params['id']]['contentType'])
                            let x = JSON.parse(realData['detail'])
                            settruefalseanswercount(x.length)
                        }
                        else {
                            console.log("hata")
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, []);

    if (!loading) {
        if (questionType === 'video') {
            let url = "https://api.heryerdeingilizce.com/" + dataa['contentStr'];
            return (
                <div style={{ position: "relative" }}>
                    <div style={myStyle3}>
                        <div class="d-flex justify-content-center">
                            {/* <iframe src={url} width="100%" height="580" frameborder="0" allow=" fullscreen; picture-in-picture" allowfullscreen></iframe> */}
                            <div className='player-wrapper' style={{ padding: "5%" }}>
                                <ReactPlayer
                                    className='react-player'
                                    url={url}
                                    width='auto'
                                    height='100%'
                                    controls="true"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="button" class="btn btn-primary btn-lg" onClick={nextquestion}>{t("continue")}</button>
                    </div>
                    <br /><hr />
                    {!loading && (

                        <div class="d-flex justify-content-center">
                            <div class="pagination">
                                {pagination && pagination.map((dt) => {
                                    if (dt.isActive) {
                                        return (
                                            <a href={dt.href} key={dt} className="active">{dt.id}</a>
                                        )
                                    }
                                    else {
                                        return (
                                            <a href={dt.href} key={dt}>{dt.id}</a>
                                        )
                                    }

                                })}

                            </div>
                        </div>
                    )}<hr />
                </div>

            )
        }
        else if (questionType === 'write') {
            let obj = JSON.parse(dataa.contentStr);
            let answers = dataa.answer.split(' ')
            let answerCount = answers.length
            let url = "http://api.heryerdeingilizce.com/" + obj['url']

            return (
                <div>
                    <br />
                    <div style={{ alignItems: "center", textAlign: "center" }}>
                        {obj['type'] === "sound" ? (
                            <ReactAudioPlayer
                                src={url}
                                autoPlay
                                controls
                            />
                        ) : (
                            <div></div>
                        )}
                        {obj['type'] === "video" ? (
                            <iframe src={url} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        ) : (
                            <div></div>
                        )}
                        {obj['type'] === "image" ? (
                            <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                                <img src={url} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <br />
                        <h4>
                            {obj['text']}
                        </h4>
                    </div>
                    <br />
                    <div style={{ alignItems: "center", textAlign: "center" }}>
                        <span>
                            {dataa.detail}
                        </span>
                    </div>
                    <br />
                    <div class="d-flex justify-content-center" style={{ fontSize: 20 }}>
                        <form onSubmit={answerWriteHandle} >

                            {answers && answers.map((dt) => {
                                return (
                                    <div class="mb-3">
                                        <input class="form-control" id="writeAnswer" required />
                                    </div>
                                )
                            })}
                            <br />
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                            </div>
                        </form>



                    </div>
                    <br />
                    <hr />
                    {!loading && (
                        <div class="d-flex justify-content-center">
                            <div class="pagination">
                                {pagination && pagination.map((dt) => {
                                    if (dt.isActive) {
                                        return (
                                            <a href={dt.href} key={dt} className="active">{dt.id}</a>
                                        )
                                    }
                                    else {
                                        return (
                                            <a href={dt.href} key={dt}>{dt.id}</a>
                                        )
                                    }

                                })}

                            </div>
                        </div>
                    )}
                    <hr />
                </div >
            )
        }
        else if (questionType === 'choice') {
            let question = JSON.parse(dataa.contentStr);
            let answers = JSON.parse(dataa.detail);
            return (
                <div>
                    <div style={{ alignItems: "center", textAlign: "center" }}>
                        {question['type'] === "sound" ? (
                            <ReactAudioPlayer
                                src={question['url']}
                                autoPlay
                                controls
                            />
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "video" ? (
                            <iframe src={question['url']} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "image" ? (
                            <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                                <img src={question['url']} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <br />
                        <h4>
                            {question['text']}
                        </h4>
                        <br /><hr />
                    </div>
                    <div class="d-flex justify-content-center" style={{ fontSize: 20, textAlign: "center" }}>
                        <form onSubmit={answerChoiceHandle} >
                            <div className="row">
                                {answers && answers.map((dt) => {
                                    return (
                                        <div class="col" >
                                            <input type="radio" style={{ height: "20px" }} id={dt.id} name="answerRate" value={dt.id} />
                                            {dt['service']}
                                            {dt['photo'] ? (
                                                <img src={dt['photo']} />
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                            <br /><br />
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                            </div>
                        </form>
                    </div>
                    <div class="d-flex justify-content-center">
                    </div>
                    <br />
                    <hr />
                    {!loading && (
                        <div class="d-flex justify-content-center">
                            <div class="pagination">
                                {pagination && pagination.map((dt) => {
                                    if (dt.isActive) {
                                        return (
                                            <a href={dt.href} key={dt} className="active">{dt.id}</a>
                                        )
                                    }
                                    else {
                                        return (
                                            <a href={dt.href} key={dt}>{dt.id}</a>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    )}
                    <hr />
                </div >
            )
        }
        else if (questionType === 'match') {
            let question = JSON.parse(dataa.contentStr);
            let answers = JSON.parse(dataa.detail);

            return (
                <div>
                    <br />
                    <div style={{ alignItems: "center", textAlign: "center" }}>
                        {question['type'] === "sound" ? (
                            <ReactAudioPlayer
                                src={question['url']}
                                autoPlay
                                controls
                            />
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "video" ? (
                            <iframe src={question['url']} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "image" ? (
                            <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                                <img src={question['url']} />
                            </div>
                        ) : (
                            <div></div>
                        )}

                        <br />
                        <h4>
                            {question['text']}
                        </h4>
                        <br /><br />
                    </div>
                    <div class="d-flex justify-content-center" style={{ fontSize: 20, textAlign: "center" }}>
                        <form onSubmit={answerMatchHandle} >
                            <div className="row">
                                {answers && answers.map((dt) => {
                                    return (
                                        <div class="col" >
                                            <span><u id={dt.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                                        </div>
                                    )
                                })}
                            </div>
                            <br /><br /><br />
                            <div className="row">

                                {answers && answers.map((dt) => {
                                    return (
                                        <div class="col" >
                                            <button className="btn btn-info" type="button" id={dt.id + ".button"} onClick={handleClick}>
                                                {dt['service']}
                                            </button>
                                            {dt['photo'] ? (
                                                <img src={dt['photo']} />
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                            <br /><br />
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                            </div>
                        </form>
                    </div>
                    <div class="d-flex justify-content-center">
                    </div>
                    <br /><hr />
                    {!loading && (
                        <div class="d-flex justify-content-center">
                            <div class="pagination">
                                {pagination && pagination.map((dt) => {
                                    if (dt.isActive) {
                                        return (
                                            <a href={dt.href} key={dt} className="active">{dt.id}</a>
                                        )
                                    }
                                    else {
                                        return (
                                            <a href={dt.href} key={dt}>{dt.id}</a>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    )}<hr />
                </div >
            )
        }
        else if (questionType === 'truefalse') {
            let question = JSON.parse(dataa.contentStr);
            let answers = JSON.parse(dataa.detail);
            return (
                <div>
                    <div style={{ alignItems: "center", textAlign: "center" }}>
                        {question['type'] === "sound" ? (
                            <ReactAudioPlayer
                                src={question['url']}
                                autoPlay
                                controls
                            />
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "video" ? (
                            <iframe src={question['url']} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "image" ? (
                            <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                                <img src={question['url']} />
                            </div>
                        ) : (
                            <div></div>
                        )}

                        <br /><br />
                        <h4>
                            {question['text']}
                        </h4>
                        <br /><hr />
                    </div>
                    <div class="d-flex justify-content-center" style={{ fontSize: 20 }}>
                        <form onSubmit={answerTrueFalseHandle} >
                            <br />
                            <div className="row">
                                <ul>
                                    {answers && answers.map((dt) => {
                                        return (
                                            <div class="col" style={{ marginLeft: "0%" }} >
                                                <h5> {dt['service']} :&nbsp;&nbsp;&nbsp;
                                                    <select id={dt.id} style={{ marginRight: "0%" }} required >
                                                        <option>{t('selectTrueOrFalse')}</option>
                                                        <option key="1" value="true">{t('true')}</option>
                                                        <option key="0" value="false" >{t('false')}</option>
                                                    </select>
                                                </h5>
                                                <br />
                                            </div>
                                        )
                                    })}
                                    <br />
                                </ul>
                            </div>
                            <br /><br />
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                            </div>
                        </form>
                    </div>
                    <div class="d-flex justify-content-center">
                    </div>
                    <br /><hr />
                    {!loading && (
                        <div class="d-flex justify-content-center">
                            <div class="pagination">
                                {pagination && pagination.map((dt) => {
                                    if (dt.isActive) {
                                        return (
                                            <a href={dt.href} key={dt} className="active">{dt.id}</a>
                                        )
                                    }
                                    else {
                                        return (
                                            <a href={dt.href} key={dt}>{dt.id}</a>
                                        )
                                    }

                                })}

                            </div>
                        </div>
                    )}<hr />
                </div >
            )
        }
        else if (questionType === 'realmatch') {
            let question = JSON.parse(dataa.contentStr);
            let answers = JSON.parse(dataa.detail);
            return (
                <div>
                    <br />
                    <div style={{ alignItems: "center", textAlign: "center" }}>
                        {question['type'] === "sound" ? (
                            <ReactAudioPlayer
                                src={question['url']}
                                autoPlay
                                controls
                            />
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "video" ? (
                            <iframe src={question['url']} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        ) : (
                            <div></div>
                        )}
                        {question['type'] === "image" ? (
                            <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                                <img src={question['url']} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <br />
                        <h4>
                            {question['text']}
                        </h4>
                        <br /><br />
                    </div>
                    <div class="d-flex justify-content-center" style={{ fontSize: 20, textAlign: "center" }}>
                        <form onSubmit={answerRealMatchHandle} >
                            <div className="row">
                                <div className="col-md-6">
                                    <ul>
                                        {answers && answers.map((dt) => {
                                            if (dt.type === 0)
                                                return (
                                                    <li>
                                                        {dt['service'] !== " " ? (
                                                            <div>
                                                                <button className="btn btn-primary" style={{ visibility: "visible", opacity: "1.0" }} type="button" id={dt.id + ".button"} onClick={handleClickrealmatch}>
                                                                    {dt['service']}
                                                                </button>
                                                                <button className="btn btn-primary" style={{ visibility: "hidden", opacity: "0.5" }} type="button" id={dt.id + ".buttons"} onClick={handleClickrealmatch}>
                                                                    {dt['service']}
                                                                </button>
                                                            </div>


                                                        ) : null}

                                                        {dt['photo'] ? (
                                                            <img src={dt['photo']} value={dt.id} id={dt.id + ".file"} onClick={handleClickrealmatchfile} style={{ width: "50%", height: "50%", opacity: "1.0" }} />
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <br /><br />
                                                    </li>
                                                )
                                        })}
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul>
                                        {answers && answers.map((dt) => {
                                            if (dt.type === 1)
                                                return (
                                                    <li>
                                                        {dt['service'] !== " " ? (
                                                            <button className="btn btn-primary" type="button" id={dt.id + ".button"} onClick={handleClickrealmatch}>
                                                                {dt['service']}
                                                            </button>
                                                        ) : null}
                                                        {dt['photo'] ? (
                                                            <img src={dt['photo']} value={dt.id} id={dt.id + ".file"} onClick={handleClickrealmatchfile} style={{ width: "50%", height: "50%", opacity: "1.0" }} />
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        <br /><br />
                                                    </li>
                                                )
                                        })}
                                    </ul>
                                </div>

                            </div>
                            <br /><br />
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                            </div>
                        </form>
                    </div>
                    <div class="d-flex justify-content-center">
                    </div>
                    <br /><hr />
                    {!loading && (
                        <div class="d-flex justify-content-center">
                            <div class="pagination">
                                {pagination && pagination.map((dt) => {
                                    if (dt.isActive) {
                                        return (
                                            <a href={dt.href} key={dt} className="active">{dt.id}</a>
                                        )
                                    }
                                    else {
                                        return (
                                            <a href={dt.href} key={dt}>{dt.id}</a>
                                        )
                                    }

                                })}

                            </div>
                        </div>
                    )}<hr />
                </div >
            )
        }
        else if (questionType === 'sentence') {
            let obj = JSON.parse(dataa.contentStr);
            let answ = JSON.parse(dataa.detail);
            let url = "http://api.heryerdeingilizce.com/" + obj['url']
            return (
                <div>
                    <br />
                    <div style={{ alignItems: "center", textAlign: "center" }}>
                        {obj['type'] === "sound" ? (
                            <ReactAudioPlayer
                                src={url}
                                autoPlay
                                controls
                            />
                        ) : (
                            <div></div>
                        )}
                        {obj['type'] === "video" ? (
                            <iframe src={url} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        ) : (
                            <div></div>
                        )}
                        {obj['type'] === "image" ? (
                            <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                                <img src={url} />
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <br />
                        <h1>
                            {obj['text']}
                        </h1>
                    </div>
                    <br />
                    <br />
                    <div class="d-flex justify-content-center" style={{ fontSize: 50 }}>
                        <form onSubmit={answerSentenceHandle} >
                            <div >
                                {answ && answ.map((dt) => {
                                    return (
                                        <div class="mb-3">
                                            <select id={dt.id} class="form-select" aria-label="Default select example" required>
                                                <option key="0" >seçim yapınız</option>
                                                {dt.service.map((aa) => {
                                                    return (
                                                        <option key={aa} value={aa}>{aa} </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    )
                                })}
                            </div>
                            <br />
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                            </div>
                        </form>
                        <br /><br />
                    </div>
                    <br /><hr />
                    {!loading && (
                        <div class="d-flex justify-content-center">
                            <div class="pagination">
                                {pagination && pagination.map((dt) => {
                                    if (dt.isActive) {
                                        return (
                                            <a href={dt.href} key={dt} className="active">{dt.id}</a>
                                        )
                                    }
                                    else {
                                        return (
                                            <a href={dt.href} key={dt}>{dt.id}</a>
                                        )
                                    }

                                })}

                            </div>
                        </div>
                    )}<hr />
                </div >
            )
        }
        else {
            return (
                <div style={myStyle}>
                    <div style={myStyle3}>
                        <div class="alert alert-light" role="alert" style={{ textAlign: "center" }}>
                            A simple light alert—check it out!
                        </div>

                        <div class="d-flex justify-content-center">
                            <iframe src="https://player.vimeo.com/video/346802568?h=fe9c8a401c&color=e89152" width="100%" height="580" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div class="d-flex justify-content-center">
                        <div class="pagination">
                            {questionCount}
                            {questionCount && questionCount.map((dt) => {
                                return (
                                    <a href="#" key={dt}>1</a>
                                )
                            })}

                        </div>
                    </div>



                </div>

            )
        }
    }


}
export default UserQuestion;