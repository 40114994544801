
import img1 from '../../images/1.jpg';
import img2 from '../../images/2.jpg';
import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./styles.css"

import belgium from '../CoursePanel/belgium.png';
import bhutan from '../CoursePanel/bhutan.png';
import brazil from '../CoursePanel/brazil.png';
import china from '../CoursePanel/china.png';

import cuba from '../CoursePanel/cuba.png';
import ecuador from '../CoursePanel/ecuador.png';


const mystyle = {
    //sayfa mobil tablet değilse. öyle full yap?    
    width: '50%',
    margin: "auto",
    align: "center"
};

function sayHello(name) {
    alert(`hello, ${name}`);
}


export const CourseDetail = () => {
    let draggableObjects;
    let dropPoints;
    const startButton = document.getElementById("start");
    const result = document.getElementById("result");
    const controls = document.querySelector(".controls-container");
    const dragContainer = document.querySelector(".draggable-objects");
    const dropContainer = document.querySelector(".drop-points");
    let deviceType = "";
    let initialX = 0,
        initialY = 0;
    let currentElement = "";
    let moveElement = false;
    const data = [
        "belgium",
        "bhutan",
        "brazil",
        "china",
        "cuba",
        "ecuador",
        // "georgia",
        // "germany",
        // "hong-kong",
        // "india",
        // "iran",
        // "myanmar",
        // "norway",
        // "spain",
        // "sri-lanka",
        // "sweden",
        // "switzerland",
        // "united-states",
        // "uruguay",
        // "wales",
    ];
    const randomValueGenerator = () => {
        return data[Math.floor(Math.random() * data.length)];
    };

    function dragStart(e) {
        if (isTouchDevice()) {
            initialX = e.touches[0].clientX;
            initialY = e.touches[0].clientY;
            //Start movement for touch
            moveElement = true;
            currentElement = e.target;
        } else {
            //For non touch devices set data to be transfered
            e.dataTransfer.setData("text", e.target.id);
        }
    }
    //Events fired on the drop target
    function dragOver(e) {
        e.preventDefault();
    }
    const touchMove = (e) => {
        if (moveElement) {
            e.preventDefault();
            let newX = e.touches[0].clientX;
            let newY = e.touches[0].clientY;
            let currentSelectedElement = document.getElementById(e.target.id);
            currentSelectedElement.parentElement.style.top = currentSelectedElement.parentElement.offsetTop - (initialY - newY) + "px";
            currentSelectedElement.parentElement.style.left = currentSelectedElement.parentElement.offsetLeft - (initialX - newX) + "px";
            initialX = newX;
            initialY = newY;
        }
    };
    const drop = (e) => {
        e.preventDefault();
        //For touch screen
        if (isTouchDevice()) {
            moveElement = false;
            //Select country name div using the custom attribute
            const currentDrop = document.querySelector(`div[data-id='${e.target.id}']`);
            //Get boundaries of div
            const currentDropBound = currentDrop.getBoundingClientRect();
            //if the position of flag falls inside the bounds of the countru name
            if (
                initialX >= currentDropBound.left &&
                initialX <= currentDropBound.right &&
                initialY >= currentDropBound.top &&
                initialY <= currentDropBound.bottom
            ) {
                currentDrop.classList.add("dropped");
                //hide actual image
                currentElement.classList.add("hide");
                currentDrop.innerHTML = "";
                //Insert new img element
                currentDrop.insertAdjacentHTML(
                    "afterbegin",
                    `<img src= "/${currentElement.id}.png">`
                );
                count += 1;
            }
        } else {
            //Access data
            const draggedElementData = e.dataTransfer.getData("text");
            //Get custom attribute value
            const droppableElementData = e.target.getAttribute("data-id");
            if (draggedElementData === droppableElementData) {
                const draggedElement = document.getElementById(draggedElementData);
                //dropped class
                e.target.classList.add("dropped");
                //hide current img
                draggedElement.classList.add("hide");
                //draggable set to false
                draggedElement.setAttribute("draggable", "false");
                e.target.innerHTML = "";
                //insert new img
                e.target.insertAdjacentHTML(
                    "afterbegin",
                    `<img src="${draggedElementData}.png">`
                );
                count += 1;
            }
        }
        //Win
        if (count == 3) {
            result.innerText = `You Won!`;
        }
    };
    const isTouchDevice = () => {
        try {
            //We try to create Touch Event (It would fail for desktops and throw error)
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (e) {
            deviceType = "mouse";
            return false;
        }
    };
    let count = 0;
    const onClick = async (e) => {
        currentElement = "";
        count = 0;
        dropPoints = document.querySelectorAll(".countries");
        draggableObjects = document.querySelectorAll(".draggable-image");
        //Events
        draggableObjects.forEach((element) => {
            element.addEventListener("dragstart", dragStart);
            //for touch screen
            element.addEventListener("touchstart", dragStart);
            element.addEventListener("touchend", drop);
            element.addEventListener("touchmove", touchMove);
        });
        dropPoints.forEach((element) => {
            element.addEventListener("dragover", dragOver);
            element.addEventListener("drop", drop);
        });
        alert("s")
    };

    return (
        <div>

            <div class="container">
                <h3>Drag & Drop The Flags Over Their Respective Country Names</h3>
                <div class="draggable-objects">
                    <div className='draggable-image' draggable="true" dragOver={dragOver} style={{ position: "absolute" }}>
                        <img src={belgium} id="belgium" />

                    </div>

                    <div className='draggable-image'>
                        <img src={china} id="china" draggable="true" dragOver={dragOver} style={{ position: "absolute" }} />

                    </div>

                    <div className='draggable-image'>
                        <img src={cuba} id="cuba" draggable="true" dragOver={dragOver} style={{ position: "absolute" }} />
                    </div>

                </div>
                <div class="drop-points"></div>
            </div>
            <div class="controls-container">
                <div>
                    <div class='countries' data-id="belgium">
                        belgium
                    </div>
                    <div class='countries' data-id="china">
                        china
                    </div>
                    <div class='countries' data-id="cuba">
                        cuba
                    </div>
                </div>
                <p id="result"></p>
                <button id="start" onClick={onClick}>Start Game</button>
            </div>

























            <hr /><hr />
            <div class="accordion" id="accordionExample" style={mystyle}>
                <div class="accordion-item">
                    <div class="card mb-2" style={{ width: "auto" }}>
                        <div class="row g-4">
                            <div class="col-md-2">
                                <img src={img1} class="img-fluid rounded-start" alt="..." />
                            </div>
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-9">
                                <div class="card-body">
                                    <h5 class="card-title">UNIT 1- 1A</h5>
                                    <p class="card-text">
                                        <h5 class="card-title">Hello!</h5>
                                    </p>
                                    <FontAwesomeIcon type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" icon="fa-solid fa-plus" style={{ fontSize: 30, color: "Mediumslateblue", boxSizing: 3, }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">



                            <div class="alert alert-light" role="alert">
                                <div class="container">
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-1">
                                            <FontAwesomeIcon icon="fa-play fa-solid" type="button" onClick={() => sayHello('James')} style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                        </div>
                                        <div className="col-2"> Lesson 1</div>

                                        <div className="col-4">
                                            <button type="button" style={{ color: "white", backgroundColor: "purple" }} class="btn">Grammer</button>
                                        </div>
                                        <div className="col-5">
                                            verb be (singular): i and love<br />  positive and negative statements
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-light" role="alert">
                                <div class="container">
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-1">
                                            <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                        </div>
                                        <div className="col-2"> Lesson 2</div>

                                        <div className="col-4">
                                            <button type="button" style={{ color: "white", backgroundColor: "coral" }} class="btn">Vocabulary</button>
                                        </div>
                                        <div className="col-5">
                                            numbers 0 to 10
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-light" role="alert">
                                <div class="container">
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-1">
                                            <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                        </div>
                                        <div className="col-2"> Lesson 3</div>

                                        <div className="col-4">
                                            <button type="button" style={{ color: "white", backgroundColor: "DarkOrchid" }} class="btn">Listening</button>
                                        </div>
                                        <div className="col-5">
                                            Listening: Hello!
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="accordion" id="accordionExample" style={mystyle}>
                <div class="accordion-item">
                    <div class="card mb-2" style={{ width: "auto" }}>
                        <div class="row g-4">
                            <div class="col-md-2">
                                <img src={img2} class="img-fluid rounded-start" alt="..." />
                            </div>
                            <div class="col-md-1">
                            </div>
                            <div class="col-md-9">
                                <div class="card-body">
                                    <h5 class="card-title">UNIT 1- 1B</h5>
                                    <p class="card-text">
                                        <h5 class="card-title">Where Are You From?</h5>
                                    </p>{/*
                                <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p>
                                
                                <button type="button" className="btn btn-info" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h4>Konu detay </h4>
                                </button>
                                 */}
                                    <FontAwesomeIcon type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" icon="fa-solid fa-plus" style={{ fontSize: 30, color: "Mediumslateblue", boxSizing: 3, }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample2">
                        <div class="accordion-body">
                            <div class="alert alert-light" role="alert">
                                <div class="container">
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-1">
                                            <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                        </div>
                                        <div className="col-2"> Lesson 1</div>

                                        <div className="col-4">
                                            <button type="button" style={{ color: "white", backgroundColor: "purple" }} class="btn">Grammer</button>
                                        </div>
                                        <div className="col-5">
                                            verb be (singular): i and love<br />  positive and negative statements
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-light" role="alert">
                                <div class="container">
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-1">
                                            <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                        </div>
                                        <div className="col-2"> Lesson 2</div>

                                        <div className="col-4">
                                            <button type="button" style={{ color: "white", backgroundColor: "coral" }} class="btn">Vocabulary</button>
                                        </div>
                                        <div className="col-5">
                                            numbers 0 to 10
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-light" role="alert">
                                <div class="container">
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-1">
                                            <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                        </div>
                                        <div className="col-2"> Lesson 3</div>

                                        <div className="col-4">
                                            <button type="button" style={{ color: "white", backgroundColor: "DarkOrchid" }} class="btn">Listening</button>
                                        </div>
                                        <div className="col-5">
                                            Listening: Hello!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default CourseDetail;